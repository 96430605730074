import React from "react";
import { logo } from "../../../../assets/icons/icons";
import { navRouteList } from "../../../../shared/consts/nav-route-list";
import { RoutesList } from "../../../RoutesList/RoutesList";
import styles from "./Header.module.scss";

interface IHeader {
  openMenu: (open: boolean) => void;
}

export const Header: React.FC<IHeader> = (props) => {
  return (
    <div className={styles.header}>
      <div className={styles.logoCont}>
        <img src={logo} alt="" className={`desktop ${styles.logo}`} />
        <div>Альфа-СТК</div>
      </div>
      <div className={styles.linksCont}>
        <RoutesList routes={navRouteList} onRouteClick={() => {}} />
      </div>
    </div>
  );
};
