import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import styles from "./ServicesSlider.module.scss";

import { Navigation, Pagination } from "swiper";

import {
  building,
  design,
  idi,
  integration,
  license,
  services,
  truck,
} from "../../assets/icons/icons";

type ServicesSliderProps = {
  viewSlides: number;
  onClick: () => any;
  onClickPO?: () => any;
};

export const ServicesSlider: React.FC<ServicesSliderProps> = (props) => {
  return (
    <>
      <Swiper
        slidesPerView={props.viewSlides}
        spaceBetween={0}
        slidesPerGroup={props.viewSlides}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className={styles.swiper}
      >
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.item} onClick={props.onClick}>
            <img src={idi} alt="" />
            <span>Инженерные изыскания (ИГИ)</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.item} onClick={props.onClick}>
            <img src={design} alt="" />
            <span>Проектирование</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.item} onClick={props.onClick}>
            <img src={building} alt="" />
            <span>Строительство</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.item} onClick={props.onClick}>
            <img src={integration} alt="" />
            <span>Интеграция (ПНР)</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.item} onClick={props.onClick}>
            <img src={services} alt="" />
            <span>Обслуживание (аутсорсинг), эксплуатация</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.item} onClick={props.onClick}>
            <img src={truck} alt="" />
            <span>Поставка оборудования</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.item} onClick={props.onClickPO}>
            <img src={license} alt="" />
            <span>Продажа ПО и лицензий</span>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
