import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { building, cable, server } from "../../../assets/icons/icons";
import { services } from "../../../assets/images/images";
import { CloseButton } from "../../CloseButton/CloseButton";
import { ServicesSlider } from "../../ServicesSlider/ServicesSlider";
import { SideTitle } from "../../SideTitle/SideTitle";
import { TitleMobile } from "../../TitleMobile/TitleMobile";
import styles from "./Services.module.scss";

const boxVariant = {
  visible: { x: 0, opacity: 1, transition: { duration: 1 } },
  hidden: { x: 50, opacity: 0 },
};

export const Services: React.FC = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalPO, setShowModalPO] = useState(false);
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <SideTitle url={services} />
        <TitleMobile text="Услуги" />
        <motion.div
          className={`desktop ${styles.content}`}
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
        >
          <ServicesSlider
            viewSlides={3}
            onClick={() => {
              setShowModal(true);
              document.body.style.overflowY = "hidden";
            }}
            onClickPO={() => {
              setShowModalPO(true);
              document.body.style.overflowY = "hidden";
            }}
          />
        </motion.div>
        <div className={`mobile ${styles.content}`}>
          <ServicesSlider
            viewSlides={1}
            onClick={() => {
              setShowModal(true);
              document.body.style.overflowY = "hidden";
            }}
            onClickPO={() => {
              setShowModalPO(true);
              document.body.style.overflowY = "hidden";
            }}
          />
        </div>
      </div>
      {showModal ? (
        <div className={styles.modal}>
          <div className={styles.info}>
            <div className={styles.title}>
              Изыскания, проектирование, строительство, интеграция, и
              обслуживание
            </div>
            <div className={styles.content}>
              <ul>
                <li>
                  Антенно мачтовые сооружения (АМС), базовые станции (БС) и
                  сопутствующая инфраструктура
                </li>
                <li>Транспортная инфраструктура (ВОЛС, РРЛ)</li>
                <li>
                  Крупносетевые объекты (ЦОДы, серверные, BSC, MSC и т.д.)
                </li>
                <li>
                  Электрические подстанции и линии электропередач (телематика,
                  КТП, ЛЭП и т.д.)
                </li>
                <li>
                  Система резервного и бесперебойного электроснабжения (ДГУ,
                  ИБП)
                </li>
                <li>Климатические системы, инженерные системы</li>
                <li>
                  Видеонаблюдение, охранно-пожарная сигнализация, система
                  контроля учета доступа
                </li>
                <li>
                  Проведение санитарно-эпидемологической экспертизы
                  проектируемых решений (форма Р1) и построение объекта (форма
                  Р2)
                </li>
              </ul>
            </div>
            <CloseButton
              onClick={() => {
                setShowModal(false);
                document.body.style.overflowY = "scroll";
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {showModalPO ? (
        <div className={styles.modal}>
          <div className={styles.info}>
            <div className={styles.title}>Продажа оборудования и ПО</div>
            <div className={styles.content}>
              <ul>
                <li>Телекоммуникационное оборудование</li>
                <li>Телематическое оборудование (телематика)</li>
                <li>
                  Источники бесперебойного и резервного питания (ДГУ, ИБП)
                </li>
                <li>
                  Климатические системы, видеонаблюдение, системы контроля
                  доступа и охранно-пожарной сигнализации
                </li>
                <li>Программное обеспечение и лицензионные ключи</li>
              </ul>
            </div>
            <div className={styles.secondTitle}>
              Наша компания предлагает оборудование, программное обеспечение и
              лицензии для формирования комплексных решений в различных сферах:
            </div>
            <div className={styles.secondContent}>
              <ul>
                <li>строительство</li>
                <li>ЖКХ</li>
                <li>транспорт и логистика</li>
                <li>инфраструктура</li>
                <li>промышленность</li>
                <li>энергетика</li>
                <li>телекоммуникации</li>
              </ul>
            </div>
            <CloseButton
              onClick={() => {
                setShowModalPO(false);
                document.body.style.overflowY = "scroll";
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
