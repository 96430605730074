import React from "react";
import styles from "./CloseButton.module.scss";

type CloseButtonProps = {
  onClick: () => any;
};

export const CloseButton: React.FC<CloseButtonProps> = (props) => {
  return <div onClick={props.onClick} className={styles.close}></div>;
};
