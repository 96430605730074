import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { projects } from "../../../assets/images/images";
import { ProjectsSlider } from "../../ProjectsSlider/ProjectsSlider";
import { SideTitle } from "../../SideTitle/SideTitle";
import { TitleMobile } from "../../TitleMobile/TitleMobile";
import styles from "./Projects.module.scss";

const boxVariant = {
  visible: { x: 0, opacity: 1, transition: { duration: 1 } },
  hidden: { x: -100, opacity: 0 },
};

export const Projects: React.FC = (props) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <SideTitle url={projects} right />
        <TitleMobile text="Проекты" />
        <motion.div
          className={`desktop ${styles.content}`}
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
        >
          <ProjectsSlider />
        </motion.div>
        <div className={`mobile ${styles.content}`}>
          <ProjectsSlider />
        </div>
      </div>
    </div>
  );
};
