import React from "react";
import { navRouteList } from "../../shared/consts/nav-route-list";
import { RoutesList } from "../RoutesList/RoutesList";
import styles from "./Menu.module.scss";

interface IMenu {
  closeMenu: (close: boolean) => void;
}

export const Menu: React.FC<IMenu> = ({ closeMenu }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <RoutesList
          routes={navRouteList}
          onRouteClick={() => {
            closeMenu(false);
            document.body.style.overflowY = "scroll";
          }}
        />
      </div>
    </div>
  );
};
