import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import styles from "./ProjectsSlider.module.scss";

import { Autoplay, Pagination, Navigation } from "swiper";
import {
  sliderImage1,
  sliderImage2,
  sliderImage3,
  sliderImage4,
  sliderImage5,
  sliderImage6,
} from "../../assets/images/images";

export const ProjectsSlider: React.FC = () => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className={styles.swiper}
      >
        <SwiperSlide className={styles.swiperSlide}>
          <img src={sliderImage1} alt="" />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <img src={sliderImage2} alt="" />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <img src={sliderImage3} alt="" />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <img src={sliderImage4} alt="" />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <img src={sliderImage5} alt="" />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <img src={sliderImage6} alt="" />
        </SwiperSlide>
      </Swiper>
    </>
  );
};
