import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { builder, pdf, tower } from "../../../assets/icons/icons";
import { about } from "../../../assets/images/images";
import { SideTitle } from "../../SideTitle/SideTitle";
import { TitleMobile } from "../../TitleMobile/TitleMobile";
import styles from "./About.module.scss";

const boxVariant = {
  visible: { x: 0, opacity: 1, transition: { duration: 1 } },
  hidden: { x: -100, opacity: 0 },
};

export const About = React.forwardRef<HTMLDivElement>((props, ref) => {
  const [countUp, setCountUp] = useState<Boolean>(false);

  const control = useAnimation();
  const [refs, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  useEffect(() => {
    if (window.scrollY > 300 && window.scrollY < 2000) {
      setCountUp(true);
    } else {
      setCountUp(false);
    }
  });

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.container}>
        <SideTitle url={about} right />
        <TitleMobile text="О нас" />
        <div className={styles.content}>
          <motion.div
            className={`desktop ${styles.textCont}`}
            ref={refs}
            variants={boxVariant}
            initial="hidden"
            animate={control}
          >
            <div className={styles.infoText}>
              <span>Альфа-СТК основана в 2007 году.</span>
              <br />
              <br />
              Компания оказывает комплекc услуг по созданию и обслуживанию
              объектов связи, энергетики и центров хранения и обработки данных,
              включая поставку оборудования и программного обеспечения.
            </div>
          </motion.div>
          <div className={`mobile ${styles.textCont}`}>
            <span>Альфа-СТК основана в 2007 году.</span>
            <br />
            <br />
            Компания оказывает комплекc услуг по созданию и обслуживанию
            объектов связи, энергетики и центров хранения и обработки данных,
            включая поставку оборудования и программного обеспечения.
          </div>
          <div className={styles.rightCont}>
            <div className={styles.infoCont}>
              <div className={styles.builders}>
                <img
                  src={builder}
                  alt=""
                  className={`desktop ${styles.builder}`}
                />
                <span>{countUp ? <CountUp end={70} /> : 0}+</span>
                сотрудников
              </div>
              <div className={styles.line}></div>
              <div className={styles.towers}>
                <img src={tower} alt="" className={`desktop ${styles.tower}`} />
                <span>
                  <span>{countUp ? <CountUp end={3000} /> : 0}+</span>
                </span>
                базовых станций
              </div>
            </div>
            <div>
              <a href="Alfa-STK.pdf" download className={styles.pdf}>
                Презентация Альфа-СТК
                <img src={pdf} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
