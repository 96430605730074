import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../Home/Home";

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<Home />} path="/" />
    </Routes>
  );
};
