import React from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import styles from "./RouteListItem.module.scss";
import { scroller } from "react-scroll";

const StyledLink = styled.a`
  color: #f5f5f5;

  :hover {
    filter: drop-shadow(-2px 3px 8px #f5f5f5);
    cursor: pointer;
  }
`;

type NavBarItemProps = {
  alias: string;
  to?: string;
  onClick?: () => any;
};

export const RouteListItem: React.FC<NavBarItemProps> = ({
  onClick,
  alias,
  to,
}) => {
  return (
    <Link
      to={to ?? "#"}
      onClick={onClick}
      className={styles.item}
      smooth={true}
      offset={0}
      duration={500}
    >
      <StyledLink>{alias}</StyledLink>
    </Link>
  );
};
