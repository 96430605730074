import React, { useEffect } from "react";
import styles from "./Modal.module.scss";
import { AppButton } from "../AppButton/AppButton";
import { motion } from "framer-motion";

const boxVariant = {
  visible: { scale: 1, opacity: 1, transition: { duration: 1 } },
  hidden: { scale: 0, opacity: 0 },
};

interface IModalComponent {
  title: string;
  description: string;
  modalIsOpen: boolean;
  closeModal: () => any;
}

export const Modal: React.FC<IModalComponent> = (props) => {
  return (
    <>
      {props.modalIsOpen ? (
        <div className={styles.wrapper}>
          <motion.div className={styles.modal}>
            <div className={styles.titleCont}>
              <h1>{props.title}</h1>
              <span>{props.description}</span>
            </div>
            <div className={styles.btnCont}>
              <AppButton
                text="ОК"
                onClick={() => {
                  props.closeModal();
                  document.body.style.overflow = "auto";
                }}
              />
            </div>
          </motion.div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
