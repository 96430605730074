import React from "react";
import styles from "./SideTitle.module.scss";

type SideTitleProps = {
  url?: string;
  right?: boolean;
};

export const SideTitle: React.FC<SideTitleProps> = ({ url, right }) => {
  return (
    <img
      src={url}
      alt=""
      className={` ${right ? styles.right : styles.left}`}
    />
  );
};
