import { motion, MotionConfig, useAnimation } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Footer } from "../Footer/Footer";
import { Menu } from "../Menu/Menu";
import { ScrollToTop } from "../ScrollToTop/ScrollToTop";
import { About } from "./About/About";
import { Clients } from "./Clients/Clients";
import { Contacts } from "./Contacts/Contacts";
import styles from "./Home.module.scss";
import { MainPage } from "./MainPage/MainPage";
import { Projects } from "./Projects/Projects";
import { Services } from "./Services/Services";

const footerAnim = {
  visible: { y: 0, opacity: 1, transition: { duration: 1 } },
  hidden: { y: 100, opacity: 0 },
};

export const Home: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const mainpage = useRef<HTMLDivElement>(null);
  const about = useRef<HTMLDivElement>(null);

  const [colorMenu, setColorMenu] = useState(false);

  useEffect(() => {
    if (window.scrollY > 700) {
      setColorMenu(true);
    } else {
      setColorMenu(false);
    }
  });

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <div className={styles.wrapper}>
      {open ? <Menu closeMenu={setOpen} /> : <div></div>}
      <div>
        <div
          className={`mobile ${
            !open ? styles.menuIcon : styles.menuIconActive
          }`}
          onClick={() => {
            if (!open) {
              setOpen(true);
              document.body.style.overflowY = "hidden";
            } else {
              setOpen(false);
              document.body.style.overflowY = "scroll";
            }
          }}
        >
          <input className={styles.checkbox} type="checkbox" />
          <div>
            <span
              className={!colorMenu ? styles.menuSpanMain : styles.menuSpan}
            ></span>
            <span
              className={!colorMenu ? styles.menuSpanMain : styles.menuSpan}
            ></span>
          </div>
        </div>
        {scrollPosition > 100 ? <ScrollToTop /> : <div></div>}
        <section id="main">
          <MainPage openMenu={setOpen} ref={mainpage} />
        </section>
        <section id="about">
          <About ref={about} />
          <Services />
        </section>
        <section id="projects">
          <Projects />
          <Clients />
        </section>
        <section id="contacts">
          <Contacts />
          <div className={`desktop ${styles.footerCont}`}>
            <motion.div
              ref={ref}
              variants={footerAnim}
              initial="hidden"
              animate={control}
            >
              <Footer />
            </motion.div>
          </div>
          <div className={`mobile ${styles.footerCont}`}>
            <Footer />
          </div>
        </section>
      </div>
    </div>
  );
};
