import React, { Children } from "react";
import styles from "./TitleMobile.module.scss";

type TitleMobileProps = {
  text: string;
} & React.HTMLProps<HTMLDivElement>;

export const TitleMobile: React.FC<TitleMobileProps> = ({ text }) => {
  return <div className={`mobile ${styles.wrapper}`}>{text}</div>;
};
