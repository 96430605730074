import React from "react";
import { IRouteListItem } from "../../shared/consts/nav-route-list";
import { RouteListItem } from "./RouteListItem/RouteListItem";
import styles from "./RoutesList.module.scss";

type RoutesListProps = {
  onRouteClick: () => any;
  routes: IRouteListItem[];
};

export const RoutesList: React.FC<RoutesListProps> = (props) => {
  return (
    <div className={styles.links}>
      {props.routes?.map((route, i) => (
        <RouteListItem
          key={route.id}
          to={route.to}
          alias={route.alias}
          onClick={props.onRouteClick}
        />
      ))}
    </div>
  );
};
