import React from "react";
import styles from "./AppButton.module.scss";

type AppButtonProps = {
  text: string;
  onClick?: () => any;
};

export const AppButton: React.FC<AppButtonProps> = ({ text, onClick }) => {
  return (
    <button className={styles.sendButton} onClick={onClick}>
      <span>{text}</span>
    </button>
  );
};
