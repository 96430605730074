import React, { useEffect, useState } from "react";
import { scrollToTop, scrollToTopHover } from "../../assets/icons/icons";
import styles from "./ScrollToTop.module.scss";

export const ScrollToTop: React.FC = () => {
  const [hover, setHover] = useState<Boolean>(false);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [scrollPosition, setScrollPosition] = useState<Boolean>(false);

  useEffect(() => {
    if (window.scrollY > 2000) {
      setScrollPosition(true);
    } else {
      setScrollPosition(false);
    }
  });

  return (
    <div
      className={scrollPosition ? styles.wrapper2 : styles.wrapper}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => goToTop()}
    >
      <img
        src={hover ? scrollToTopHover : scrollToTop}
        alt=""
        className={styles.arrow}
      />
    </div>
  );
};
