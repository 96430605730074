import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { b2b, b2g } from "../../../assets/icons/icons";
import {
  beeline,
  clients,
  ericsson,
  megafon,
  mts,
  nokia,
} from "../../../assets/images/images";
import { CloseButton } from "../../CloseButton/CloseButton";
import { SideTitle } from "../../SideTitle/SideTitle";
import { TitleMobile } from "../../TitleMobile/TitleMobile";
import styles from "./Clients.module.scss";
import { ClientsItem } from "./ClientsItem/ClientsItem";

const images = [
  {
    img: beeline,
    url: "https://beeline.ru/",
  },
  {
    img: mts,
    url: "https://mts.ru/",
  },
  {
    img: megafon,
    url: "https://megafon.ru/",
  },
  {
    img: ericsson,
    url: "https://www.ericsson.com/",
  },
  {
    img: nokia,
    url: "https://www.nokia.com/",
  },
];

const boxVariant = {
  visible: { x: 0, opacity: 1, transition: { duration: 1 } },
  hidden: { x: 50, opacity: 0 },
};

export const Clients: React.FC = () => {
  const [showModalb2g, setShowModalb2g] = useState(false);
  const [showModalb2b, setShowModalb2b] = useState(false);
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <div className={styles.wrapper}>
      <SideTitle url={clients} />
      <div className={styles.container}>
        <TitleMobile text="Клиенты" />
        <div className={styles.content}>
          <div
            className={styles.b2b}
            onClick={() => {
              setShowModalb2b(true);
              document.body.style.overflowY = "hidden";
            }}
          >
            <img src={b2b} alt="" />
            <span>B2B</span>
          </div>
          <div
            className={styles.b2g}
            onClick={() => {
              setShowModalb2g(true);
              document.body.style.overflowY = "hidden";
            }}
          >
            <img src={b2g} alt="" />
            <span>B2G</span>
          </div>
        </div>
        {showModalb2b ? (
          <div className={styles.modal}>
            <div className={styles.item}>
              <ul>
                <li>ПАО “ВЫМПЕЛКОМ”</li>
                <li>ПАО “МегаФон”</li>
                <li>ПАО “МТС”</li>
                <li>АО “Национальная башенная компания”</li>
                <li>АО “Первая башенная компания”</li>
                <li>АО “Эрикссон Корпорация АО”</li>
                <li>АО “Электросвязь” в ЧР</li>
                <li>АО “Нокиа Солюшн Энд Нетворкс”</li>
                <li>ПАО “Россети Северный Кавказ”</li>
                <li>АО “Чеченэнерго”</li>
              </ul>
              <CloseButton
                onClick={() => {
                  setShowModalb2b(false);
                  document.body.style.overflowY = "scroll";
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {showModalb2g ? (
          <div className={styles.modal}>
            <div className={styles.item}>
              <ul>
                <li>ФСБ РФ</li>
                <li>ФСО РФ</li>
                <li>МВД РФ</li>
                <li>ФССП</li>
              </ul>
              <CloseButton
                onClick={() => {
                  setShowModalb2g(false);
                  document.body.style.overflowY = "scroll";
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
