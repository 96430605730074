import { motion } from "framer-motion";
import React from "react";
import { ReactComponent as Email } from "../../../assets/icons/email.svg";
import { ReactComponent as Location } from "../../../assets/icons/location.svg";
import { ReactComponent as Phone } from "../../../assets/icons/phone.svg";
import { backMobile, MainLogo } from "../../../assets/images/images";
import { AppButton } from "../../AppButton/AppButton";
import { Header } from "./Header/Header";
import styles from "./MainPage.module.scss";

interface IMainPage {
  openMenu: (open: boolean) => void;
}

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
  hidden: { opacity: 0, scale: 0 },
};

export const MainPage = React.forwardRef<HTMLDivElement, IMainPage>(
  (props, ref) => {
    return (
      <div ref={ref} className={styles.wrapper}>
        <div className={styles.mainImageCont}></div>
        <div className={styles.main}></div>
        <Header openMenu={props.openMenu} />
        <motion.div
          variants={boxVariant}
          initial="hidden"
          animate="visible"
          className={styles.content}
        >
          <div className={styles.title}>
            Строительство и обслуживание объектов связи, энергетического
            комплекса, центров обработки и хранения данных
          </div>
          <div className={styles.btnCont}>
            <AppButton
              onClick={() => window.scrollTo({ top: 5000, behavior: "smooth" })}
              text="Обратная связь"
            />
          </div>
          <div className={styles.infoCont}>
            <a href="tel:88712223364" className={styles.phoneCont}>
              <Phone className={styles.phone} />
              <span>+7(8712) 22-33-64</span>
            </a>
            <a href="mailto:info@alfastk.ru" className={styles.mailCont}>
              <Email className={styles.mail} />
              <span>info@alfastk.ru</span>
            </a>
            <a
              href="https://www.google.com/maps/place/%D1%83%D0%BB.+%D0%A1.+%D0%A8.+%D0%9B%D0%BE%D1%80%D1%81%D0%B0%D0%BD%D0%BE%D0%B2%D0%B0,+%D0%93%D1%80%D0%BE%D0%B7%D0%BD%D1%8B%D0%B9,+%D0%A7%D0%B5%D1%87%D0%B5%D0%BD%D1%81%D0%BA%D0%B0%D1%8F+%D0%A0%D0%B5%D1%81%D0%BF.,+364061/@43.3212375,45.6912708,19.5z/data=!4m5!3m4!1s0x4051d3e0ba4a3ed1:0x6dd6d58cf374d4b3!8m2!3d43.3212511!4d45.691231?hl=RU"
              className={styles.addressCont}
            >
              <Location className={styles.address} />
              <span>
                Чеченская Республика, <br />
                г. Грозный, ул. Лорсанова 10.
              </span>
            </a>
          </div>
        </motion.div>
      </div>
    );
  }
);
