export interface IRouteListItem {
  id: number;
  alias: string;
  to?: string;
}

export const navRouteList: IRouteListItem[] = [
  {
    id: 1,
    alias: "Главная",
    to: "main",
  },
  {
    id: 2,
    alias: "О нас",
    to: "about",
  },
  {
    id: 3,
    alias: "Услуги",
    to: "about",
  },
  {
    id: 4,
    alias: "Проекты",
    to: "projects",
  },
  {
    id: 5,
    alias: "Клиенты",
    to: "projects",
  },
  {
    id: 6,
    alias: "Контакты",
    to: "contacts",
  },
];
