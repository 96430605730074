import React, { useRef, useState } from "react";
import { AppButton } from "../AppButton/AppButton";
import styles from "./FormContact.module.scss";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { Modal } from "../Modal/Modal";

export const FormContact = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [count, setCount] = useState(0);
  const form = useRef();

  function openModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_49ob3zp",
        "template_qa6el6n",
        form.current,
        "qKA9CTJnM_qRaXzRL"
      )
      .then(
        (result) => {
          setIsOpen(true);
          setIsError(false);
          document.body.style.overflowY = "hidden";
        },
        (error) => {
          setIsOpen(true);
          setIsError(true);
          document.body.style.overflowY = "hidden";
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail} className={styles.container}>
      <div className={styles.inputCont}>
        {!isError ? (
          <Modal
            title="Успешно!"
            description="Ваше сообщение доставлено:)"
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
          />
        ) : (
          <Modal
            title="Ошибка!"
            description="Ваше сообщение не было доставлено:(. Попробуйте позже."
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
          />
        )}
        <input
          type="text"
          placeholder="Имя"
          id="fname"
          name="user_name"
          className={styles.item}
          minLength={3}
          maxLength={12}
          required
        />
        <span className={styles.validity}></span>
      </div>
      <div className={styles.inputCont}>
        <input
          id="telNo"
          name="user_phone"
          type="tel"
          required
          defaultValue="+7"
          pattern="[+][0-9]{1}[0-9]{3}[0-9]{3}[0-9]{4}"
          className={styles.item}
        />
        <span className={styles.validity}></span>
      </div>
      <div className={styles.inputCont}>
        <input
          type="email"
          placeholder="Email"
          id="email"
          name="user_email"
          className={styles.item}
          required
        />
        <span className={styles.validity}></span>
      </div>
      <div className={styles.messageCont}>
        <textarea
          placeholder="Сообщение"
          id="message"
          name="message"
          className={styles.message}
          minLength={3}
          maxLength={200}
          onChange={(e) => setCount(e.target.value.length)}
          required
        />
        <span className={styles.validity}></span>
      </div>
      <div className={styles.btnCont}>
        <span className={styles.count}>{count}/200</span>
        <AppButton text="Отправить" onClick={() => {}} />
      </div>
    </form>
  );
};
