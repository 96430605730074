import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { contacts, man } from "../../../assets/images/images";
import { FormContact } from "../../FormContact/FormContact";
import { SideTitle } from "../../SideTitle/SideTitle";
import styles from "./Contacts.module.scss";
import { ReactComponent as Phone } from "../../../assets/icons/phone.svg";
import { ReactComponent as Email } from "../../../assets/icons/email.svg";

const titleAnim = {
  visible: { x: 0, opacity: 1, transition: { duration: 1 } },
  hidden: { x: -100, opacity: 0 },
};
const form = {
  visible: { y: 0, opacity: 1, transition: { duration: 1 } },
  hidden: { y: 100, opacity: 0 },
};
const manAnim = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
  hidden: { opacity: 0, scale: 0 },
};

export const Contacts: React.FC = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.left}>
          <motion.div
            className={`desktop ${styles.title}`}
            ref={ref}
            variants={titleAnim}
            initial="hidden"
            animate={control}
          >
            Свяжитесь с нами!
          </motion.div>
          <div className={`mobile ${styles.title}`}>Свяжитесь с нами!</div>
          <motion.div
            className={`desktop ${styles.leftCont}`}
            ref={ref}
            variants={form}
            initial="hidden"
            animate={control}
          >
            <FormContact />
          </motion.div>
          <div className={`mobile ${styles.leftCont}`}>
            <FormContact />
          </div>
        </div>
        <div className={styles.rightCont}>
          <SideTitle url={contacts} right />
          <motion.div
            className={`desktop ${styles.man}`}
            ref={ref}
            variants={manAnim}
            initial="hidden"
            animate={control}
          >
            <div>
              <img src={man} alt="" />
            </div>
            <div className={styles.name}>
              Кагиров Марат <br /> Асланбекович
            </div>
            <div className={styles.position}>Генеральный директор</div>
            <div className={styles.phoneCont}>
              <Phone className={styles.icon} />
              <a href="tel:89389979999" className={styles.phone}>
                +7(938)997-99-99
              </a>
            </div>
            <div className={styles.mailCont}>
              <Email className={styles.icon} />
              <a href="mailto:m.kagirov@alfastk.ru" className={styles.email}>
                m.kagirov@alfastk.ru
              </a>
            </div>
          </motion.div>
          <div className={`mobile ${styles.man}`}>
            <div>
              <img src={man} alt="" />
            </div>
            <div className={styles.name}>
              Кагиров Марат <br /> Асланбекович
            </div>
            <div className={styles.position}>Генеральный директор</div>
            <div className={styles.phoneCont}>
              <Phone className={styles.icon} />
              <a href="tel:89389979999" className={styles.phone}>
                +7(938)997-99-99
              </a>
            </div>
            <div className={styles.mailCont}>
              <Email className={styles.icon} />
              <a href="mailto:m.kagirov@alfastk.ru" className={styles.email}>
                m.kagirov@alfastk.ru
              </a>
            </div>
          </div>
          <motion.div
            className={`desktop ${styles.man}`}
            variants={manAnim}
            initial="hidden"
            animate={control}
          >
            <div>
              <img src={man} alt="" />
            </div>
            <div className={styles.name}>
              Харсиев Саид <br /> Баширович
            </div>
            <div className={styles.position}>Технический директор</div>
            <div className={styles.phoneCont}>
              <Phone className={styles.icon} />
              <a href="tel:89389979999" className={styles.phone}>
                +7(906)488-36-36
              </a>
            </div>
            <div className={styles.mailCont}>
              <Email className={styles.icon} />
              <a href="mailto:s.kharsiev@alfastk.ru " className={styles.email}>
                s.kharsiev@alfastk.ru
              </a>
            </div>
          </motion.div>
          <div className={`mobile ${styles.man}`}>
            <div>
              <img src={man} alt="" />
            </div>
            <div className={styles.name}>
              Харсиев Саид <br /> Баширович
            </div>
            <div className={styles.position}>Технический директор</div>
            <div className={styles.phoneCont}>
              <Phone className={styles.icon} />
              <a href="tel:89389979999" className={styles.phone}>
                +7(906)488-36-36
              </a>
            </div>
            <div className={styles.mailCont}>
              <Email className={styles.icon} />
              <a href="mailto:s.kharsiev@alfastk.ru " className={styles.email}>
                s.kharsiev@alfastk.ru
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
