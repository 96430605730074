import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./components/AppRouter/AppRoutes";

const loader = document.getElementById("loader");

function App(): JSX.Element {
  useEffect(() => {
    setTimeout(() => {
      loader?.classList.add("loader--hide");
    }, 300);
  }, []);
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
